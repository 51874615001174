

import {

  Map,

} from 'src/Modules';

export default () => [

  {
    path: '/',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: Map
  },

]